import React from 'react';

import Layout from '@layouts';
import { Banners, SEO, Join } from '@components';

const { Join: JoinBanner } = Banners;

const Index = () => (
  <Layout bannerChildren={<JoinBanner />} bannerStyle={{ backgroundImage: 'none' }}>
    <SEO
      title='JOIN US'
      description='Apply to become a contributor or for a position at The Africa I Know, Inc.'
    />

    <Join />
  </Layout>
);

export default Index;
